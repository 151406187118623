<template>
  <div class="add">
    <p class="add_title">请输入要查询的宝贝，每行一个</p>
    <p class="add_span">平台支持，淘宝、天猫、京东、拼多多、抖音、快手、闲鱼、1688商品</p>
    <el-input
      type="textarea"
      :rows="13"
      style="width: 900px"
      placeholder="请输入要查询的宝贝"
      v-model="textarea"
    >
    </el-input>
    <div class="add_serve">
      <el-select v-model="value" placeholder="请选择数据监控方案" clearable>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-date-picker
      v-model="source_time"
      type="date"
      value-format="yyyy-MM-dd"
      placeholder="请选择收录日期">
    </el-date-picker>
      
      <el-select v-model="control_val" placeholder="请选择管控类型" clearable>
        <el-option
          v-for="item in control"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select v-model="levelId" placeholder="请选择价格级别" clearable>
        <el-option
          v-for="item in level"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <p class="pa" v-if="tip">暂无数据监控方案，请先<span class="set_span" @click="handleTask">设置</span>定时查询！</p>

    <div>
      <el-button
        type="primary"
        class="submit"
        size="small"
        icon="el-icon-success"
        @click="handleSubmit"
        :loading="loading"
        >保存</el-button
      >
      <el-button
        size="small"
        style="width: 80px"
        @click="handleBack"
        icon="el-icon-back"
        >返回</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textarea: "",
      loading: false,
      // 日期
      source_time:'',
      //定时任务
      options: [],
      value: "",
      // 管控类型
      control:[
         { label: '控价', value: '控价' },
          { label: '打假', value: '打假' },
          { label: '禁售', value: '禁售' },
          { label: '侵权', value: '侵权' },
          { label: '监控', value: '监控' },
      ],
      level:[],//价格级别
      levelId:"",
      control_val:"",
      tip: false, //提示
    };
  },
  created() {
    this.LowPrice();
    // 价格级别
    this.handleThird();
  },
  methods: {
    // 加载价格级别
    handleThird() {
      this.$axios
        .get("/api/v6/PricesLevel", {})
        .then((res) => {
          if (res.data.status == 0) {
            let data = res.data.data;
            this.level = [];
            for (let i in data) {
              this.level.push({ label: data[i].name, value: data[i].id });
            }
          } else {
            this.$message({
              message: "加载错误",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取数据；
    LowPrice() {
      this.loading = true;
      this.$axios.get("/api/v6/Schedule", {}).then((res) => {
        console.log(res);
        if (res.data.status == 0) {
          if (res.data.data.legnth == 0) {
            this.tip = true;
            return;
          }
          for (let i in res.data.data) {
            this.options.push({
              value: res.data.data[i].id,
              label: res.data.data[i].name,
            });
          }
        }
        this.loading = false;
      });
    },
    // 保存
    handleSubmit() {
      if (this.textarea == "") {
        this.$message({
          message: "请输入内容后在提交",
          type: "warning",
        });
        return;
      } else if (this.textarea.indexOf("http") == -1) {
        this.$message({
          message: "链接错误，请检查是否填写正确",
          type: "warning",
        });
        return;
      } else {
        let ares = this.textarea.split("\n");
        if (ares.length > 500) {
          this.$message({
            message: "单次添加链接不得超过500条",
            type: "warning",
          });
          return;
        }
      }
      if (this.value == "") {
        this.$message({
          message: "请选择定时任务",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      this.$axios
        .add("/api/v6/SpuSubmit", {
          text:this.textarea,
          tb_schedule_id: this.value,
          tb_prices_level_id:this.levelId,
          control_type:this.control_val,
          source_time:this.source_time,
        }, {})
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            this.$message({
              message: "添加成功",
              type: "success",
            });
            this.textarea = "";
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
    },
    // 返回上一页
    handleBack() {
      this.$router.push({ name: "home" });
    },
    // 定时任务页面
    handleTask(){
      this.$router.push({name:'addTask'})
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  width: 100%;
  height: 100%;
  min-width: 1392px;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 1000px;
  }
  .add_title {
    font-size: 16px;
    color: $text-color;
    font-weight: bold;
  }
  .add_span {
    font-size: 14px;
    color: #898989;
    margin-bottom: 24px;
  }
  .submit {
    width: 80px;
    background-color: #3978f7;
    margin-top: 24px;
  }
}
.add_serve {
  width: 900px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .el-select{
    margin-right: 10px;
  }
  .el-date-editor{
    margin-right: 10px;
  }
}
.pa{
    color: #999999;
    margin-top: 10px;
    .set_span {
      color: rgb(0, 140, 255);
      text-decoration: underline;
      padding: 0 3px;
      cursor: pointer;
    }
  }
</style>
